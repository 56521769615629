import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { RadialDashboardComponent } from './components/radial-dashboard/radial-dashboard.component';
import { CpRoutes } from './cp-route-constants';
import { AuthGuard } from './services/auth-service/auth.guard';
const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'auth', component: AppComponent },
  {
    path: CpRoutes.RealtimeDashboard,
    component: RadialDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: CpRoutes.PurchaseOrderDetails,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/inbound-metrics/inbound-metrics.module').then(
        (m) => m.InboundMetricsModule
      ),
  },
  {
    path: CpRoutes.OrderDetails,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/outbound-metrics/outbound-metrics.module').then(
        (m) => m.OutboundMetricsModule
      ),
  },
  {
    path: CpRoutes.ReturnDetails,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/return-metrics/return-metrics.module').then(
        (m) => m.ReturnMetricsModule
      ),
  },
  {
    path: CpRoutes.UserConfig,
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./components/user-management/user-management.component'),
  },
  {
    path: CpRoutes.ClientConfig,
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./components/client-management/client-management.component'),
  },
  {
    path: CpRoutes.InventoryDetails,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/inventory/inventory.routes').then(
        (m) => m.inventoryRoutes
      ),
  },
  {
    path: CpRoutes.OrdersOnHold,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/order-viability/order-viability.module').then(
        (m) => m.OrderViabilityModule
      ),
  },
  {
    path: CpRoutes.Kitting,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/kitting/kitting.module').then(
        (m) => m.KittingModule
      ),
  },
  {
    path: CpRoutes.Forecast,
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/forecast/forecast.module').then(
        (m) => m.ForecastModule
      ),
    data: { type: 'site' },
  },
  {
    path: CpRoutes.SitePlanningForecast,
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/forecast/forecast.module').then(
        (m) => m.ForecastModule
      ),
    data: { type: 'site' },
  },
  {
    path: CpRoutes.VolumeForecast,
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/forecast/forecast.module').then(
        (m) => m.ForecastModule
      ),
    data: { type: 'volume' },
  },
  {
    path: CpRoutes.PeakForecast,
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/forecast/forecast.module').then(
        (m) => m.ForecastModule
      ),
    data: { type: 'peak' },
  },
  {
    path: CpRoutes.Parameter,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/parameter-page/parameter-page.module').then(
        (m) => m.ParameterPageModule
      ),
    data: { key: 'parameter' },
  },
  {
    path: CpRoutes.HistoricDashboard,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './components/data-warehouse/data-warehouse-routes/datawarehouse.routes'
        ).then((m) => m.DATA_DASHBOARD_ROUTES),
  },
  {
    path: CpRoutes.ReportBuilder,
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        './components/data-warehouse/report-builder/report-builder.component'
        ),
  },
  {
    path: CpRoutes.OntimeShipSLA,
    loadChildren: () =>
      import('./components/sla-kpi/sla-kpi.routes').then((m) => m.SLAKPIRoutes),
    canActivate: [AuthGuard],
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
  {
    path: '**',
    component: NoAccessComponent,
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
