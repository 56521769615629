// this file we are using to add some global function to window object
window.deepClone = (obj) => {
  if (typeof obj !== 'object') {
    return new Error('deepClone can be used only on object ', obj);
  }
  if ('structuredClone' in window) {
    return window.structuredClone(obj);
  } else {
    return JSON.parse(JSON.stringify(obj));
  }
};
