import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class InterceptorService {
  constructor(public snackBar: MatSnackBar, public authService: OAuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `${this.authService.getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    });
    let headers = request.headers;
    if (
      !request?.url.includes(this.authService.issuer) &&
      this.authService.hasValidAccessToken()
    ) {
      // added cname and uuid headersv are not included in User details API, rest are applied
      if (
        !request?.url.includes(environment.pingUrl) &&
        !request?.url.includes(environment.userConfigUrl + 'userdetails')
      ) {
        headers = headers
          .set('cname', sessionStorage.getItem('companyname') || '')
          .set('uuid', sessionStorage.getItem('loginuseruuid'))
          .set(
            'warehouses',
            sessionStorage.getItem('selectedWareHouses') || ''
          );
      }
      const cloneReq = request.clone({ headers });
      return next.handle(cloneReq).pipe(
        tap((data) => {
          // If client side error happens it will get validated except dashboard realtime API to
          // avoid snackbar multiple times(setInterval is implemented in Dashboard)
          if (data instanceof HttpResponse) {
            if (
              !data.url.includes(environment.baseUrl + '/inbound') &&
              !data.url.includes(environment.baseUrl + '/return') &&
              !data.url.includes(environment.baseUrl + '/outbound') &&
              data?.body?.statusmessage === 'Fail'
            ) {
              this.showErrors(data);
            }
          }
        }),
        catchError((res) => this.handleError(res))
      );
    } else {
      headers = new HttpHeaders({});
      const cloneReq = request?.clone({ headers });
      return next?.handle(cloneReq).pipe(
        tap(() => {
          // If client side error happens it will get validated except dashboard realtime API
        }),
        catchError((res) => this.handleError(res))
      );
    }
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (
      error?.error instanceof ErrorEvent ||
      error?.error?.statuscode === 400
    ) {
      // Get client-side error
      errorMessage =
        error?.error?.message ||
        error?.error?.response?.errorMsg ||
        error?.error?.response;
    } else {
      // Get server-side error
      errorMessage = 'API Failure.';
    }
    this.snackBar.open(errorMessage, 'close', {
      duration: 5000,
      panelClass: ['error-snack-bar'],
    });
    if (error?.status === 401 || error?.status === 403) {
      //sessionStorage.clear();
      //localStorage.clear();
      this.authService.logOut();
    }
    return throwError(error);
  }
  showErrors(data: any) {
    this.snackBar.open(data.body.response.errorMsg || 'Failed', 'close', {
      duration: 5000,
      panelClass: ['error-snack-bar'],
    });
  }
}
