import { CommonModule,NgOptimizedImage } from '@angular/common';
import { Component,OnDestroy,OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSidenav } from '@angular/material/sidenav';
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subject,filter,takeUntil } from 'rxjs';
import { SubmitFeedbackComponent } from 'src/app/components/submit-feedback/submit-feedback.component';
import { CpRoutes } from 'src/app/cp-route-constants';
import { MaterialModule } from 'src/app/material/material.module';
import { ClientPortalService } from 'src/app/services/client-user-config-service/client-portal.service';
import { UserModule } from 'src/app/services/client-user-config-service/model.interface';
import { UserService } from 'src/app/services/user-service/user.service';
import { WarehouseService } from 'src/app/services/warehouse-service/warehouse.service';
import { WarehouseDropdownComponent } from '../../warehouse-dropdown/warehouse-dropdown.component';
import { GlobalVariable } from '../constants/global-variable';
import { FooterComponent } from '../footer/footer.component';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MaterialModule,
    CommonModule,
    RouterOutlet,
    NgOptimizedImage,
    RouterModule,
    FormsModule,
    WarehouseDropdownComponent,
    FooterComponent,
  ],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'expandable-overlay-panel' },
    },
  ],
  templateUrl: './header.component.html',
  styleUrl: './scss/header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
  buttonText = 'SELECT';
  selectedWarehouse = ['OUT'];
  selectedClient = '';
  userDetails: any;
  clients: any;
  clientsFromUserDetails: any[];
  selectedCompany: any;
  roleName = '';
  roleAcronym: string;
  clientName: any;
  ModulesList: any[];
  permittedRoutes: any[];
  flatObjOfModules: any;
  nestedMenus: any;
  userName = '-- --';
  companyname = '--------';
  destroy$: Subject<boolean> = new Subject<boolean>();
  clientList: any = [];
  menuIcons = {
    dashboard: 'space_dashboard',
    inbound: 'trending_down',
    outbound: 'trending_up',
    return: 'keyboard_return',
    parameter: 'settings_applications',
    sla: 'speed',
    datawh: 'warehouse',
    ordersonhold: 'pause_circle',
    inventory: 'inventory_2',
  };
  clientEmail: any;
  hideExpandablePanel: boolean;
  newNavList: any[];
  modules;
  menuNumberToOpen: number;
  /**
   * below urls are those urls for which we do not want to show
   * either client dd or warehouse dd
   */
  shouldShowWareHouseDD = true;
  shouldShowClientDD = true;
  clientDDExceptionUrls = [
    `/${CpRoutes.ClientConfig}`,
    `/${CpRoutes.UserConfig}`,
  ];
  wareHouseDDExceptionUrls = [
    `^/${CpRoutes.Parameter}`,
    `^/${CpRoutes.ClientConfig}`,
    `^/${CpRoutes.UserConfig}`,
    `^/${CpRoutes.InventoryDetails}`,
    `^/${CpRoutes.InventoryDetailsInfo}`,
    `^/${CpRoutes.OrderDetailsInfo}/[^/]+$`,
    `^/${CpRoutes.OrdersOnHold}/[^/]+$`,
    `^/${CpRoutes.KittingOrderDetail}`,
    `^/${CpRoutes.KittingOrdersImport}`,
    `^/${CpRoutes.SitePlanningForecast}`,
    `^/${CpRoutes.PeakForecast}`,
    `^/${CpRoutes.VolumeForecast}`,
    `^/${CpRoutes.ImportForecastData}`,
    `^/${CpRoutes.PurchaseOrderDetailsInfo}/[^/]+$`,
    `^/${CpRoutes.OntimeShipSLAGraph}`,
  ];
  constructor(
    public router: Router,
    public oauthService: OAuthService,
    public userService: UserService,
    public clientPortalSerivce: ClientPortalService,
    public wareHouseService: WarehouseService,
    public submitFeeback: MatDialog
  ) {
    console.log('header component loaded');
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.shouldShowClientDD = !this.clientDDExceptionUrls.some((url) => {
          const regEx = new RegExp(url);
          return regEx.exec(e.url);
        });
        this.shouldShowWareHouseDD = !this.wareHouseDDExceptionUrls.some(
          (url) => {
            const regEx = new RegExp(url);
            return regEx.exec(e.url);
          }
        );
      }
    });
  }
  ngOnInit(): void {
    if (sessionStorage.getItem('companyname')) {
      this.companyname = sessionStorage.getItem('companyname').trim();
    }
    // ping users
    this.userService
      .getLoggedInUserInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          console.log('got logged in user info header compent');
          this.userName = `${user.firstName} ${user.lastName}`;
          this.clientEmail = user.mail.trim();
          this.companyname = user.companyName.trim();
          this.roleName = user.role || '';
          GlobalVariable.companyname = this.companyname;
        }
      });
    this.clientPortalSerivce.logInUserDetails
      .pipe(
        takeUntil(this.destroy$),
        filter((data) => data?.length > 0)
      )
      .subscribe((data: any) => {
        console.log('got logInUserDetails header compent ', data);
        this.userDetails = data;
        /**below code we have added as sometimes proer user role does not come from user info
         * api
         */
        if (this.roleName.length === 0) {
          this.roleName = data[0].role_name;
        }
        this.getClientListFromUserDetails();
      });
  }
  toggleMenu(menu: MatSidenav, btn: MatIconButton) {
    /**
     * below code will remove foucs from the menubutton
     * other wise once we close the side menu after
     * routing we will a gray circle around menu button
     */
    btn._elementRef?.nativeElement?.blur();
    if (!menu.opened) {
      this.updateModulesExpandedState();
    }
    menu.toggle();
    this.shouldBodyScroll(!menu.opened);
  }
  updateModulesExpandedState() {
    const companyName = sessionStorage.getItem('companyname')?.trim();
    const routeInfo = this.clientPortalSerivce.getActiveRouteInfo(
      companyName,
      this.router.url
    );
    if (routeInfo) {
      const ids = [];
      const routeId = routeInfo.id.toString();
      for (let i = 1; i <= routeId.length; i++) {
        ids.push(routeId.substring(0, i));
      }
      this.clearExpansion(this.ModulesList, ids);
    } else {
      console.log(
        `route info not avialable for ${companyName} and route ${this.router.url}`
      );
    }
  }
  shouldBodyScroll(shouldScroll = true) {
    const bodyClasses = document.body.classList;
    if (shouldScroll) {
      bodyClasses.remove('no-scroll');
    } else {
      bodyClasses.add('no-scroll');
    }
  }
  logout() {
    this.oauthService.logOut();
    //sonar-ignore
    //In future we will remove below commented code
    //this.router.navigate(['/'], { replaceUrl: true });
    //sessionStorage.clear();
  }
  onWareHouseChange() {
    this.clientPortalSerivce.warehouseUpdated$.next(true);
  }
  goToFirstModule() {
    this.router.navigate([this.permittedRoutes[0] || 'no-access']);
  }
  getClientListFromUserDetails() {
    this.clientsFromUserDetails =
      this.clientPortalSerivce.getAllowedClientsForLoggedInUser();
    this.selectedCompany = sessionStorage.getItem('companyname')
      ? sessionStorage.getItem('companyname')
      : this.clientsFromUserDetails[0].client_acronym;
    this.getModulesByClient(this.selectedCompany);
  }
  getModulesByClient(client: string, selectedFromDropdown = false) {
    this.selectedCompany = client;
    this.ModulesList = this.clientPortalSerivce.getModulesByClient(client);
    this.permittedRoutes = this.clientPortalSerivce.getPermittedRoute(client);
    this.userService.selectedClientInHeader.next(client);
    this.clientPortalSerivce.currentSelectedClient.next(client);
    this.clientPortalSerivce.logInUserModules.next(this.ModulesList);
    this.ModulesList = this.clientPortalSerivce.addConfigMenuOptions(
      this.ModulesList
    );
this.clientPortalSerivce.permittedMenuItems = this.permittedRoutes;
    sessionStorage.clear();
    sessionStorage.setItem('companyname', client);
    sessionStorage.setItem('loginuseruuid', this.userDetails[0].uuid.trim());
    this.setWareHousesnNavigate(client, selectedFromDropdown);
    //sonar-ignore
    //In future we will remove below commented code
    //if (selectedFromDropdown) {
    //  //  to refresh the component route without browser reload,so it clears the entered input fileds when client change
    //  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //    this.goToFirstModule();
    //  });
    //} else {
    //  this.goToFirstModule();
    //}
    /**
     * below code will make sure that opened expanion panel get closed automatically
     */
    this.menuNumberToOpen = undefined;
  }
  setWareHousesnNavigate(client: string, isFromDD = false) {
    this.wareHouseService.allClientWareHouses$
      .pipe(
        takeUntil(this.destroy$),
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((wareHouses: Array<any>) => {
        const filterdWarehouse = wareHouses.filter(
          (w) => w.client_acronym === client
        );
        if (filterdWarehouse.length > 0) {
          this.wareHouseService.specificClientWareHouses.next(
            filterdWarehouse[0].sites
          );
          const facilityIds = filterdWarehouse[0].sites?.map(
            (element) => element?.facility_id
          );
          sessionStorage.setItem('selectedWareHouses', facilityIds.toString());
        } else {
          console.log('could not find warehouse details for client ', client);
          this.router.navigate(['/no-access']);
          return;
        }
        if (isFromDD) {
          //  to refresh the component route without browser reload,so it clears the entered input fileds when client change
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.goToFirstModule();
            });
        } else {
          this.goToFirstModule();
        }
        //sonar-ignore
        //below code we will remove in future
        // for (const res of response) {
        //   if (res?.client_acronym === client) {
        //     this.wareHouseService.specificClientWareHouses.next(res?.sites);
        //     const wareHouses = res?.sites?.map(
        //       (element) => element?.facility_id
        //     );
        //     sessionStorage.setItem('selectedWareHouses', wareHouses.toString());
        //   }
        // }
      });
  }
  openFeedbackForm(): void {
    this.submitFeeback.open(SubmitFeedbackComponent, {
      autoFocus: false,
      minWidth: '40vw',
      height: 'auto',
      maxWidth: '100%',
      data: { email: this.clientEmail },
    });
  }
  userInfoInsideClicked(e: Event) {
    e.stopImmediatePropagation();
    e.preventDefault();
  }
  openThisMenuAndCloseOthers(
    currentMenuIndex: number,
    allModules: UserModule[],
    module: UserModule
  ) {
    this.menuNumberToOpen = currentMenuIndex;
    this.clearExpansion(allModules);
    module.isExpanded = true;
  }
  clearExpansion(modules: UserModule[], exceptionIDs: string[] = []) {
    /**
     * scenario not covered
     * what if group is having multile inner child and
     * inner child is collapased
     */
    const clear = (module: UserModule[]) => {
      module.forEach((mod: UserModule) => {
        if (mod?.children?.length > 0) {
          if (!exceptionIDs.includes(mod.id.toString())) {
            mod.isExpanded = false;
          } else {
            mod.isExpanded = true;
          }
          clear(mod.children);
        }
      });
    };
    clear(modules);
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  //old methods
  // getClientListFromUserDetails() {
  //   let client = [];
  //   if (this.userDetails.length > 0) {
  //     this.roleAcronym = this.userDetails[0].role_acronym;
  //     this.userDetails?.forEach((el) => {
  //       client.push({
  //         client_name: el.client_name,
  //         client_acronym: el.client_acronym,
  //       });
  //     });
  //     // remove duplicate from object client names
  //     client = [...new Set(client.map((el: any) => JSON.stringify(el)))].map(
  //       (el: any) => JSON.parse(el)
  //     );
  //     this.clientsFromUserDetails = [...client];
  //     this.selectedCompany = sessionStorage.getItem('companyname')
  //       ? sessionStorage.getItem('companyname')
  //       : this.clientsFromUserDetails[0].acrm;
  //     this.roleName =
  //       this.roleAcronym === 'ADM'
  //         ? 'Admin'
  //         : this.roleAcronym === 'AMR'
  //         ? 'Account Manager'
  //         : this.roleAcronym === 'SUR'
  //         ? 'Super User'
  //         : 'User';
  //     this.roleName = this.userDetails[0].role_name;
  //     this.getModulesByClient(this.selectedCompany);
  //   }
  // }
  // getModulesByClient(client, selectedFromDropdown?) {
  //   //temp code
  //   this.modules =
  //     this.clientPortalSerivce.transformedUserDetails.modules[client];
  //   //end
  //   this.clientsFromUserDetails.forEach((e) => {
  //     if (client === e.client_acronym) {
  //       this.clientName = e.client_name;
  //     }
  //   });
  //   this.ModulesList = [];
  //   this.permittedRoutes = [];
  //   this.userDetails?.forEach((ele) => {
  //     if (ele.client_acronym === client) {
  //       this.flatObjOfModules = ele.modules.map((el) => el);
  //       this.ModulesList = ele.modules.map((el) => el);
  //     }
  //   });
  //   this.ModulesList.forEach((el) => {
  //     this.permittedRoutes.push(el.routing_url);
  //   });
  //   /*  note when ever new modules/child modules are added in application routing, it should be added here to give access/restrict
  //    based on requirements, if not added you cant search from url but can access from routerlink buttons */
  //   this.permittedRoutes = [...this.permittedRoutes];
  //   this.userService.selectedClientInHeader.next(this.selectedCompany);
  //   this.clientPortalSerivce.logInUserModules.next(this.ModulesList);
  //   this.clientPortalSerivce.permittedMenuItems = this.permittedRoutes;
  //   sessionStorage.clear();
  //   sessionStorage.setItem('companyname', client);
  //   sessionStorage.setItem('loginuseruuid', this.userDetails[0].uuid.trim());
  //   if (selectedFromDropdown) {
  //     //  this.wareHouseService.
  //     this.getWareHouseDropDownList();
  //     //  to refresh the component route without browser reload,so it clears the entered input fileds when client change
  //     this.router
  //       .navigateByUrl('/', { skipLocationChange: true })
  //       .then(() =>
  //         this.router.navigate([
  //           `${this.ModulesList[0].module_name.toLowerCase()}`,
  //         ])
  //       );
  //   }
  // }
  // ngOnInit(): void {
  //   this.selectedWarehouse = ['OUT'];
  //   this.getClientListFromUserDetails();
  // }
  // getClientListFromUserDetails() {
  //   let client = [];
  //   if (this.userDetails.length > 0) {
  //     this.userDetails?.forEach((el) => {
  //       client.push({
  //         client_name: el.client_name,
  //         client_acronym: el.client_acronym,
  //       });
  //     });
  //     // remove duplicate from object client names
  //     client = [...new Set(client.map((el: any) => JSON.stringify(el)))].map(
  //       (el: any) => JSON.parse(el)
  //     );
  //     this.clientsFromUserDetails = [...client];
  //     this.selectedCompany = sessionStorage.getItem('companyname')
  //       ? sessionStorage.getItem('companyname')
  //       : this.clientsFromUserDetails[0].acrm;
  //     this.roleName =
  //       this.roleAcronym === 'ADM'
  //         ? 'Admin'
  //         : this.roleAcronym === 'AMR'
  //         ? 'Account Manager'
  //         : this.roleAcronym === 'SUR'
  //         ? 'Super User'
  //         : 'User';
  //     this.roleName = this.userDetails[0].role_name;
  //     this.getModulesByClient(this.selectedCompany);
  //   }
  // }
  // getModulesByClient(client, selectedFromDropdown?) {
  //   this.clientsFromUserDetails.forEach((e) => {
  //     if (client === e.acrm) {
  //       this.clientName = e.name;
  //     }
  //   });
  //   this.ModulesList = [];
  //   this.permittedRoutes = [];
  //   this.userDetails?.forEach((ele) => {
  //     if (ele.client_acronym === client) {
  //       this.flatObjOfModules = ele.modules.map((el) => el);
  //       this.createNestedMenusFromModules(this.flatObjOfModules);
  //       this.ModulesList = ele.modules.map((el) => el);
  //     }
  //   });
  //   this.ModulesList.forEach((el) => {
  //     this.permittedRoutes.push(el.module_name.toLowerCase());
  //   });
  //   /*  note when ever new modules/child modules are added in application routing, it should be added here to give access/restrict
  //    based on requirements, if not added you cant search from url but can access from routerlink buttons */
  //   this.permittedRoutes = [...this.permittedRoutes];
  //   this.userService.selectedClientInHeader.next(this.selectedCompany);
  //   this.clientPortalSerivce.logInUserModules.next(this.ModulesList);
  //   const formattedMenuItems = this.permittedRoutes.map((el) =>
  //     el.replace(/\s/g, '')
  //   );
  //   this.clientPortalSerivce.permittedMenuItems = formattedMenuItems;
  //   sessionStorage.clear();
  //   sessionStorage.setItem('companyname', client);
  //   sessionStorage.setItem('loginuseruuid', this.userDetails[0].uuid.trim());
  //   if (selectedFromDropdown) {
  //     //  this.wareHouseService.
  //     this.getWareHouseDropDownList();
  //     //  to refresh the component route without browser reload,so it clears the entered input fileds when client change
  //     this.router
  //       .navigateByUrl('/', { skipLocationChange: true })
  //       .then(() =>
  //         this.router.navigate([
  //           `${this.ModulesList[0].module_name.toLowerCase()}`,
  //         ])
  //       );
  //   }
  // }
  // canShow(item: string) {
  //   if (item === 'action') {
  //     this.hideExpandablePanel = true;
  //     return false;
  //   } else {
  //     this.hideExpandablePanel = false;
  //     return true;
  //   }
  // }
  // selectModulesList(client) {
  //   this.ModulesList = [];
  //   this.getModulesByClient(client);
  // }
}
