import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  baseUrl = environment.baseUrl;
  http = inject(HttpClient);
  submitFeedback(data) {
    return this.http.post<any>(this.baseUrl + '/submitfeedback', data);

  }
}
