<div
  class="radial-dashboard-content cp-page-padding"
  ngStyle.xs="height: inherit"
  ngStyle.lt-md="height: inherit"
>
  <app-page-heading
    ><span style="font-size: 55%" ngStyle.xs="font-size: 70%">
      | {{ currentDate | date : "yyyy-MM-dd" }}</span
    ></app-page-heading
  >
  <div
    fxLayout="row wrap"
    fxLayoutGap="19px grid"
    class="dashboard-grid-tiles-alignment"
  >
    <div fxFlex="33.33%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="33.33%">
      <mat-card appearance="outlined" class="mat-elevation-z11">
        <div id="icon-row">
          <h2>
            <span class="material-icons" style="vertical-align: inherit">
              trending_down </span
            >Inbound Metrics
            <div
              *ngIf="inboundKpiMetricsSpinner"
              style="float: right; margin-left: 11px; padding-top: 6px"
            >
              <mat-spinner diameter="20"></mat-spinner>
            </div>
          </h2>
          <button
            *ngIf="showInboundMetrics"
            mat-icon-button
            (click)="navigateToListView('/' + cpRoutes.PurchaseOrderDetails)"
          >
            <mat-icon>add_link</mat-icon>
          </button>
        </div>
        <mat-error *ngIf="!!inbounderrorMessage">{{
          inbounderrorMessage || "Unknown error occured!"
        }}</mat-error>
        <mat-grid-list cols="2" [rowHeight]="rowHeight" [gutterSize]="'12px'">
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of PO Processed
              <div class="bold">
                {{ inboundMetrics?.inbound_po_processed || 0 | number : "1." }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of ASN Processed
              <div class="bold">
                {{
                  inboundKpiMetrics?.response?.inbound_asn_processed || 0
                    | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of SKU's Received
              <div class="bold">
                {{
                  inboundKpiMetrics?.response?.inbound_sku_received || 0
                    | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              Received Units
              <div class="bold">
                {{
                  inboundMetrics?.inbound_received_units || 0 | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card>
    </div>
    <div fxFlex="33.33%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="33.33%">
      <mat-card appearance="outlined" class="mat-elevation-z11">
        <div id="icon-row">
          <h2>
            <span class="material-icons"> trending_up</span>Outbound Metrics
            <div
              *ngIf="outboundKpiMetricsSpinner"
              style="float: right; margin-left: 11px; padding-top: 6px"
            >
              <mat-spinner diameter="20"></mat-spinner>
            </div>
          </h2>
          <button
            *ngIf="showOutboundMetrics"
            mat-icon-button
            (click)="navigateToListView('/' + cpRoutes.OrderDetails)"
          >
            <mat-icon>add_link</mat-icon>
          </button>
        </div>
        <mat-error *ngIf="!!outbounderrorMessage">{{
          outbounderrorMessage || "Unknown error occured!"
        }}</mat-error>
        <mat-grid-list cols="2" [rowHeight]="rowHeight" [gutterSize]="'12px'">
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of Orders Received
              <div class="bold">
                {{
                  outboundMetrics?.outbound_order_received || 0 | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              Orders Received Units
              <div class="bold">
                {{
                  outboundMetrics?.outbound_received_units || 0 | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of Orders Picked
              <div class="bold">
                {{
                  outboundMetrics?.outbound_order_picked || 0 | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              Orders Picked Units
              <div class="bold">
                {{ outboundMetrics?.outbound_picked_unit || 0 | number : "1." }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of Orders Packed
              <div class="bold">
                {{
                  outboundMetrics?.outbound_order_packed || 0 | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              Orders Packed Units
              <div class="bold">
                {{
                  outboundMetrics?.outbound_packed_units || 0 | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of Orders Shipped
              <div class="bold">
                {{
                  outboundMetrics?.outbound_order_shipped || 0 | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              Orders Shipped Units
              <div class="bold">
                {{
                  outboundMetrics?.outbound_shipped_units || 0 | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of Open Orders
              <div class="bold">
                {{ outboundMetrics?.outbound_open_orders || 0 | number : "1." }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of Open Units
              <div class="bold">
                {{ outboundMetrics?.outbound_open_units || 0 | number : "1." }}
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card>
    </div>
    <div fxFlex="33.33%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="33.33%">
      <mat-card appearance="outlined" class="mat-elevation-z11">
        <div id="icon-row">
          <h2>
            <span class="material-icons"> keyboard_return </span>Return Metrics
            <div
              *ngIf="returnKpiMetricsSpinner"
              style="float: right; margin-left: 11px; padding-top: 6px"
            >
              <mat-spinner diameter="20"></mat-spinner>
            </div>
          </h2>
          <button
            *ngIf="showReturnMetrics"
            mat-icon-button
            (click)="navigateToListView('/' + cpRoutes.ReturnDetails)"
          >
            <mat-icon>add_link</mat-icon>
          </button>
        </div>
        <mat-error *ngIf="!!returnerrorMessage">{{
          returnerrorMessage || "Unknown error occured!"
        }}</mat-error>
        <mat-grid-list cols="2" [rowHeight]="rowHeight" [gutterSize]="'12px'">
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              Orders Processed
              <div class="bold">
                {{
                  returnMetrics?.returned_order_processed || 0 | number : "1."
                }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              Orders Putaway
              <div class="bold">
                {{ returnMetrics?.return_order_putaway || 0 | number : "1." }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of SKU's Returned
              <div class="bold">
                {{ returnMetrics?.returned_sku || 0 | number : "1." }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              Returned Units
              <div class="bold">
                {{ returnMetrics?.returned_unit || 0 | number : "1." }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              No. of SKU's Putaway
              <div class="bold">
                {{ returnMetrics?.return_sku_putaway || 0 | number : "1." }}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" class="outset script-menu" colspan="1">
            <div>
              Putaway Units
              <div class="bold">
                {{ returnMetrics?.return_putaway_units || 0 | number : "1." }}
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card>
    </div>
  </div>
</div>
