import { NgIf, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterOutlet } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { authConfig } from './auth/auth-config';
import { BaseService } from './services/base-service/base.service';
import { ClientPortalService } from './services/client-user-config-service/client-portal.service';
import { UserDetailsAPIPayload } from './services/client-user-config-service/model.interface';
import { UserService } from './services/user-service/user.service';
import { WarehouseService } from './services/warehouse-service/warehouse.service';
import { GlobalVariable } from './shared/layout/constants/global-variable';
import { HeaderComponent } from './shared/layout/header/header.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressBarModule,
    HeaderComponent,
    RouterOutlet,
    NgOptimizedImage,
  ],
})
export class AppComponent implements OnDestroy, OnInit {
  loggedIn: any;
  route: any;
  users: any;
  radialUserInfoFromPing: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  count: any;
  userDetails: any;
  clientList: any = [];
  overallDetails: any = [];
  moduleNotAssign: boolean;
  showNoAccess: boolean;
  logInUserRoleAcronym: any;
  clientsFromUserDetails: any[];
  selectedCompany: any;
  logInUserRoleName: string;
  ModulesList: any = [];
  showHeader: boolean;
  permittedRoutes: any = [];
  namesFromModulesList: any = [];
  noAccessPage = '/no-access';
  constructor(
    public oauthService: OAuthService,
    public router: Router,
    public baseService: BaseService,
    public userService: UserService,
    public snackBar: MatSnackBar,
    public clientPortalService: ClientPortalService,
    public warehouseService: WarehouseService
  ) {}
  ngOnInit() {
    if (!this.oauthService.hasValidAccessToken()) {
      this.showHeader = false;
      this.ConfigureImplicitFlowAuthentication();
    } else {
      if (sessionStorage.getItem('companyname')) {
        GlobalVariable.companyname = sessionStorage
          .getItem('companyname')
          ?.trim();
      }
    }
  }
  ConfigureImplicitFlowAuthentication() {
    this.oauthService.setStorage(localStorage);
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService
      .loadDiscoveryDocument()
      .then(() => {
        this.oauthService.tryLogin();
      })
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          setTimeout(() => {
            this.getUserDetaisFromPing();
          }, 100);
        } else {
          this.oauthService.initImplicitFlow();
          this.showHeader = false;
        }
      });
  }
  getUserDetaisFromPing() {
    if (this.oauthService.hasValidAccessToken()) {
      this.baseService
        .getUserInfo()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data.statusmessage === 'success') {
            this.radialUserInfoFromPing = data.response;
            this.storeLoggedInUserInfo('', '', '');
            sessionStorage.setItem(
              'loginuseruuid',
              data.response.entryUUID.trim()
            );
            if (data.response.companyName) {
              this.getWareHouseDropDownList();
              this.getUserDetailsFromRadialAPI();
            } else {
              // If user has no company name
              this.snackBar.open(
                'User is not configured correctly, please contact your administrator',
                'close',
                {
                  duration: 5000,
                  panelClass: ['mat-toolbar', 'mat-primary'],
                }
              );
              sessionStorage.clear();
              window.location.reload();
            }
          }
        });
    } else {
      sessionStorage.clear();
      localStorage.clear();
      window.location.reload();
    }
  }
  getUserDetailsFromRadialAPI() {
    const UUID = sessionStorage.getItem?.('loginuseruuid');
    const payLoad: UserDetailsAPIPayload = {
      company_name: '',
      uuid: UUID,
      search_uuid: UUID,
    };
    this.showHeader = false;
    this.clientPortalService
      .getUserDetails(payLoad)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.userDetails = data.response;
          if (data.statusmessage === 'Success' && this.userDetails.length > 0) {
            this.storeLoggedInUserInfo(
              this.userDetails[0].uuid,
              this.userDetails[0].role_name,
              this.userDetails[0].role_acronym
            );
            this.clientPortalService.processUserDetailsAPIResponse(
              this.userDetails
            );
            this.selectedCompany = this.userDetails[0].client_acronym.trim();
            sessionStorage.setItem('companyname', this.selectedCompany);
            GlobalVariable.companyname = this.selectedCompany;
            //sonar-ignore
            //In future we will remove below commented code
            //this.getWareHouseDropDownList();
            this.clientPortalService.logInUserDetails.next(data.response);
            //sonar-ignore
            //In future we will remove below commented code
            //this.logInUserRoleAcronym = this.userDetails[0].role_acronym;
            //this.getModulesByClient(this.selectedCompany);
          } else if (
            (data.response.errorCode === 'Err_1006' &&
              data.statusmessage === 'Fail') ||
            data.response.length === 0
          ) {
            this.router.navigate([this.noAccessPage]);
          }
          this.showHeader = true;
        },
        error: (e) => {
          console.log('error while calling user details api ', e);
          this.router.navigate([this.noAccessPage]);
          this.showHeader = true;
        },
      });
  }
  getWareHouseDropDownList() {
    this.warehouseService.getWarehouses().subscribe({
      next: (data: any) => {
        const response = data?.response;
        this.warehouseService.allClientWareHouses$.next(response);
      },
      error: (e) => {
        console.log('error while calling ware house list api', e);
        this.router.navigate([this.noAccessPage]);
      },
    });
  }
  storeLoggedInUserInfo(UUID: string, rol: string, roleAcnym: string): void {
    this.radialUserInfoFromPing.UUID = UUID;
    this.radialUserInfoFromPing.role = rol;
    this.radialUserInfoFromPing.role_acc = roleAcnym;
    this.userService.addUser(this.radialUserInfoFromPing);
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  // old and extra
  // redirectToDashboard() {
  //   this.getUserDetailsFromOurApi();
  // }
  // getUserDetaisFromPing() {
  //   if (this.oauthService.hasValidAccessToken()) {
  //     this.baseService
  //       .getUserInfo()
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe((data) => {
  //         if (data.statusmessage === 'success') {
  //           this.users = data.response;
  //           this.userService.addUser(this.users);
  //           sessionStorage.setItem(
  //             'loginuseruuid',
  //             data.response.entryUUID.trim()
  //           );
  //           if (data.response.companyName) {
  //             this.getUserDetailsFromOurApi();
  //           } else {
  //             // If user has no company name
  //             this.snackBar.open(
  //               'User is not configured correctly, please contact your administrator',
  //               'close',
  //               {
  //                 duration: 5000,
  //                 panelClass: ['mat-toolbar', 'mat-primary'],
  //               }
  //             );
  //             sessionStorage.clear();
  //             window.location.reload();
  //           }
  //         }
  //       });
  //   } else {
  //     sessionStorage.clear();
  //     localStorage.clear();
  //     window.location.reload();
  //   }
  // }
  /* istanbul ignore next */
  //  getUserDetailsFromOurApi() {
  //   const UUID = sessionStorage.getItem?.('loginuseruuid');
  //   const payLoad: UserDetailsAPIPayload = {
  //     company_name: '',
  //     uuid: UUID,
  //     search_uuid: UUID,
  //   };
  //   this.showHeader = false;
  //   this.clientPortalService
  //     .getUserDetailsForNewNav(payLoad)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((data: any) => {
  //       let client = [];
  //       this.userDetails = data.response;
  //       if (data.statusmessage === 'Success' && this.userDetails.length > 0) {
  //         this.clientPortalService.processUserDetailsAPIResponse(
  //           this.userDetails
  //         );
  //         sessionStorage.setItem(
  //           'companyname',
  //           this.userDetails[0].client_acronym.trim()
  //         );
  //         GlobalVariable.companyname =
  //           this.userDetails[0].client_acronym.trim();
  //         this.getWareHouseDropDownList();
  //         this.clientPortalService.logInUserDetails.next(data.response);
  //         this.logInUserRoleAcronym = this.userDetails[0].role_acronym;
  //         this.userDetails?.forEach((el) => {
  //           client.push({
  //             name: el.client_name,
  //             acrm: el.client_acronym,
  //           });
  //         });
  //         // remove duplicate from object client names
  //         client = [
  //           ...new Set(client.map((el: any) => JSON.stringify(el))),
  //         ].map((el: any) => JSON.parse(el));
  //         this.clientsFromUserDetails = [...client];
  //         sessionStorage.setItem(
  //           'companyname',
  //           this.clientsFromUserDetails[0].acrm
  //         );
  //         this.selectedCompany = sessionStorage.getItem('companyname')
  //           ? sessionStorage.getItem('companyname')
  //           : this.clientsFromUserDetails[0].acrm;
  //         this.logInUserRoleName =
  //           this.logInUserRoleAcronym === 'ADM'
  //             ? 'Admin'
  //             : this.logInUserRoleAcronym === 'AMR'
  //             ? 'Account Manager'
  //             : this.logInUserRoleAcronym === 'SUR'
  //             ? 'Super User'
  //             : 'User';
  //         this.getModulesByClient(this.selectedCompany);
  //       } else if (
  //         (data.response.errorCode === 'Err_1006' &&
  //           data.statusmessage === 'Fail') ||
  //         data.response.length === 0
  //       ) {
  //         this.showHeader = true;
  //         this.router.navigate(['/no-access']);
  //       }
  //     });
  // }
  // getModules(data) {
  //   this.moduleNotAssign = data;
  // }
  // getModulesByClient(client) {
  //   let path;
  //   this.ModulesList = [];
  //   this.permittedRoutes = [];
  //   this.namesFromModulesList = [];
  //   this.userDetails?.forEach((ele) => {
  //     if (ele.client_acronym === client) {
  //       this.ModulesList = ele.modules.map((item) => item);
  //     }
  //   });
  //   this.userService.selectedClientInHeader.next(this.selectedCompany);
  //   this.clientPortalService.logInUserModules.next(this.ModulesList);
  //   // No need to validate existing route for the first time
  //   if (['/auth', '/', '/auth#'].includes(this.router.url)) {
  //     path = this.ModulesList[0]?.module_name?.toLowerCase();
  //     this.router.navigate([`${path}`]);
  //   }
  //   this.permittedRoutes = [];
  //   this.ModulesList.forEach((el) => {
  //     this.permittedRoutes.push(el.routing_url);
  //   });
  //   /*  note when ever new modules/child modules are added in application routing, it should be added here to give access/restrict
  //    based on requirements, if not added you cant search from url but can access from routerlink buttons */
  //   this.permittedRoutes = [
  //     ...this.permittedRoutes,
  //     ...(this.logInUserRoleAcronym === 'ADM' ||
  //     this.logInUserRoleAcronym === 'SUR' ||
  //     this.logInUserRoleAcronym === 'AMR'
  //       ? ['usermanagement']
  //       : []),
  //   ];
  //   // const formattedMenuItems = this.permittedRoutes.map((el) =>
  //   //   el.replace(/\s/g, '')
  //   // );
  //   this.clientPortalService.permittedMenuItems = this.permittedRoutes;
  //   const matchedroute = this.permittedRoutes.filter((route) =>
  //     this.router.url.replace('/', '').startsWith(route)
  //   );
  //   if (matchedroute[0]) {
  //     path = matchedroute[0]?.toLowerCase();
  //   } else {
  //     path = this.ModulesList[0]?.routing_url?.toLowerCase();
  //   }
  //   //  this is for dynamic route navigate based on client modules access
  //   this.router.navigate([`${path}`]);
  // }
  // getWareHouseDropDownList() {
  //   // const clientName = sessionStorage.getItem('companyname');
  //   this.warehouseService.getWarehouses().subscribe((data: any) => {
  //     const response = data?.response;
  //     this.warehouseService.allClientWareHouses.next(response);
  //     // we have commented below code to check what will happend without it
  //     // date : 19-feb-2024 1.30pm
  //     //this.showHeader = true;
  //     // for (const res of response) {
  //     //   if (res?.client_acronym === clientName) {
  //     //     this.warehouseService.specificClientWareHouses.next(res?.sites);
  //     //     const wareHouses = res?.sites?.map((element) => element?.facility_id);
  //     //     sessionStorage.setItem('selectedWareHouses', String(wareHouses));
  //     //   }
  //     // }
  //     //end
  //   });
  // }
}
