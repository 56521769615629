<div style="padding: 20px">
  <h1 style="color: var(--radialRed)">Submit Your Feedback</h1>
  <mat-card appearance="outlined" class="mat-elevation-z8">
    <form [formGroup]="feedbackForm">
      <div>
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Contact Email Id</mat-label>
          <input
            matInput
            placeholder="Email Id"
            required
            formControlName="usrEntEmail"
          />
          <mat-error>Please enter Contact Email Id</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Please Select Subject</mat-label>
          <mat-select formControlName="subject">
            <mat-option
              *ngFor="let sub of subjects"
              [value]="sub.acronym"
              required
            >
              {{ sub.name }}
            </mat-option>
          </mat-select>
          <mat-error>Please select subject</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" style="width: 100%">
          <textarea
            #comments
            required
            rows="5"
            formControlName="comments"
            maxlength="3000"
            matInput
            placeholder="Enter your Comments.."
          ></textarea>
          <mat-hint [align]="'end'">{{ comments.value.length }}/3000</mat-hint>
          <mat-error>Please enter comments</mat-error>
        </mat-form-field>
      </div>
      <div mat-card-actions style="text-align: center">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="submitFeedback()"
        >
          Submit
        </button>
        <button
          type="button"
          style="margin-left: 10px"
          mat-raised-button
          (click)="dialogRef.close()"
        >
          Close
        </button>
      </div>
    </form>
  </mat-card>
  <mat-progress-bar mode="indeterminate" *ngIf="showLoader"></mat-progress-bar>
</div>
