import { Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [MatToolbarModule, FlexModule],
})
export class FooterComponent {
  appversion: string;
  isProduction = false;
  branchName: string;
  branchURL: string;
  constructor() {
    this.appversion = environment.VERSION;
    this.isProduction = environment.production;
    if (!this.isProduction) {
      this.branchName = environment.DEPLOYED_BRANCH_NAME;
      this.branchURL = environment.DEPLOYED_BRANCH_URL;
    }
  }
}
