<!-- @if(!showHeader || router.url === '/' || router.url === '/auth' ||
router.url.includes('/auth#')){ -->
@if(!showHeader || router.url.startsWith('/auth')){
<div class="loader">
  <div>
    <img
      class="img-responsive mat-icon-margin-left"
      alt="Radial"
      width="143"
      height="44"
      ngSrc="assets/images/radial_logo_2023.png"
      priority
    />
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
} @if(showHeader){
<app-header> </app-header>
}
