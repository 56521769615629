import { DatePipe, DecimalPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subject, Subscription, timer } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { CpRoutes } from 'src/app/cp-route-constants';
import { ClientPortalService } from 'src/app/services/client-user-config-service/client-portal.service';
import { PageHeadingComponent } from 'src/app/shared/page-heading/page-heading.component';
import { KpiMetricsService } from '../../services/kpi-metrics/kpi-metrics.service';
import { UserService } from '../../services/user-service/user.service';
import { WarehouseDropdownComponent } from '../../shared/warehouse-dropdown/warehouse-dropdown.component';
import { InboundKpiMetrics } from './../../models/inbound-kpi-metrics.model';
import { OutboundKpiMetrics } from './../../models/outbound-kpi-metrics.model';
import { ReturnKpiMetrics } from './../../models/return-kpi-metrics.model';
import { GlobalVariable } from './../../shared/layout/constants/global-variable';
@Component({
  selector: 'app-radial-dashboard',
  templateUrl: './radial-dashboard.component.html',
  styleUrls: ['./radial-dashboard.component.css'],
  standalone: true,
  imports: [
    ExtendedModule,
    WarehouseDropdownComponent,
    FlexModule,
    MatCardModule,
    NgIf,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatGridListModule,
    DecimalPipe,
    DatePipe,
    PageHeadingComponent,
  ],
})
export class RadialDashboardComponent implements OnInit, OnDestroy {
  rowHeight = '77px';
  currentDate = new Date();
  inboundKpiMetrics: InboundKpiMetrics;
  inboundMetrics: any;
  outboundKpiMetrics: OutboundKpiMetrics;
  outboundMetrics: any;
  returnKpiMetrics: ReturnKpiMetrics;
  returnMetrics: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  inbounderrorMessage: string;
  outbounderrorMessage: string;
  returnerrorMessage: string;
  showOutboundMetrics: boolean;
  showReturnMetrics: boolean;
  showInboundMetrics: boolean;
  inboundKpiMetricsSpinner: boolean;
  outboundKpiMetricsSpinner: boolean;
  returnKpiMetricsSpinner: boolean;
  inboundMetricsSubscription: Subscription;
  returnMetricsSubscription: Subscription;
  outboundMetricsSubscription: Subscription;
  cpRoutes = CpRoutes;
  constructor(
    public kpiMetricsService: KpiMetricsService,
    public clientPortalService: ClientPortalService,
    public userService: UserService,
    public router: Router
  ) {}
  ngOnInit(): void {
    this.userService.selectedClientInHeader
      .pipe(
        takeUntil(this.destroy$),
        filter((client) => client && client?.length > 0)
      )
      .subscribe((client) => {
        GlobalVariable.companyname = client;
        this.inboundMetrics = [];
        this.outboundMetrics = [];
        this.returnMetrics = [];
        this.inbounderrorMessage = '';
        this.outbounderrorMessage = '';
        this.returnerrorMessage = '';
        this.showOutboundMetrics = this.clientPortalService.isAcronymAllowed(
          client,
          'OUT'
        );
        this.showReturnMetrics = this.clientPortalService.isAcronymAllowed(
          client,
          'RET'
        );
        this.showInboundMetrics = this.clientPortalService.isAcronymAllowed(
          client,
          'INB'
        );
        this.getKpiMetrics(client.trim());
      });
    this.clientPortalService.warehouseUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.callApiBySelectedWareHouse();
      });
  }
  callApiBySelectedWareHouse() {
    this.getKpiMetrics(GlobalVariable.companyname);
  }
  getKpiMetrics(client: string) {
    this.getInboundKpiMetrics(client);
    this.getOutboundKpiMetrics(client);
    this.getReturnKpiMetrics(client);
  }
  getInboundKpiMetrics(client: string) {
    this.inboundKpiMetricsSpinner = true;
    if (this.inboundMetricsSubscription) {
      this.inboundMetricsSubscription.unsubscribe();
    }
    this.inboundMetricsSubscription = timer(0, 100000)
      .pipe(mergeMap(() => this.kpiMetricsService.getInboundKpiMetrics(client)))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.inboundKpiMetricsSpinner = false;
          this.inboundKpiMetrics = data;
          this.inboundMetrics = this.inboundKpiMetrics.response;
          this.inbounderrorMessage = '';
          if (this.inboundKpiMetrics.statusmessage === 'Fail') {
            this.inbounderrorMessage = this.inboundMetrics.errorMsg;
          } else {
            this.inbounderrorMessage = '';
          }
        },
        error: (httpRes) => {
          this.inboundKpiMetrics = httpRes;
          this.inboundKpiMetricsSpinner = false;
        },
      });
  }
  getOutboundKpiMetrics(client: string) {
    if (this.outboundMetricsSubscription) {
      this.outboundMetricsSubscription.unsubscribe();
    }
    this.outboundKpiMetricsSpinner = true;
    this.outboundMetricsSubscription = timer(0, 60000)
      .pipe(
        mergeMap(() => this.kpiMetricsService.getOutboundKpiMetrics(client))
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.outboundKpiMetricsSpinner = false;
          this.outboundKpiMetrics = data;
          this.outboundMetrics = this.outboundKpiMetrics.response;
          this.outbounderrorMessage = '';
          if (this.outboundKpiMetrics.statusmessage === 'Fail') {
            this.outbounderrorMessage = this.outboundMetrics.errorMsg;
          }
        },
        error: (httpRes) => {
          this.outboundKpiMetrics = httpRes;
          this.outboundKpiMetricsSpinner = false;
        },
      });
  }
  getReturnKpiMetrics(client: string) {
    if (this.returnMetricsSubscription) {
      this.returnMetricsSubscription.unsubscribe();
    }
    this.returnKpiMetricsSpinner = true;
    this.returnMetricsSubscription = timer(0, 200000)
      .pipe(mergeMap(() => this.kpiMetricsService.getReturnKpiMetrics(client)))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.returnKpiMetricsSpinner = false;
          this.returnKpiMetrics = data;
          this.returnerrorMessage = '';
          this.returnMetrics = this.returnKpiMetrics.response;
          if (this.returnKpiMetrics.statusmessage === 'Fail') {
            this.returnerrorMessage = this.returnMetrics.errorMsg;
          }
        },
        error: (httpRes) => {
          this.returnKpiMetrics = httpRes;
          this.returnKpiMetricsSpinner = false;
        },
      });
  }
  navigateToListView(module) {
    this.router.navigate([`${module}`]);
    this.userService.isClickedOnDashboardIcon = true;
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
//old code that we will remove in future
/**
 * ngOnInit(): void {
    // this.clientPortalService.logInUserModules
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((data) => {
    //     if (data != null) {
    //       this.showOutboundMetrics =
    //         this.clientPortalService.isAcronymAllowed();
    //       this.showReturnMetrics = data?.some(
    //         (el) => el.name.toLowerCase() === 'return'
    //       );
    //       this.showInboundMetrics = data?.some(
    //         (el) => el.name.toLowerCase() === 'inbound'
    //       );
    //     }
    //   });
    this.userService.selectedClientInHeader
      .pipe(
        takeUntil(this.destroy$),
        filter((client) => client && client?.length > 0)
      )
      .subscribe((client) => {
        GlobalVariable.companyname = client;
        this.inboundMetrics = [];
        this.outboundMetrics = [];
        this.returnMetrics = [];
        this.inbounderrorMessage = '';
        this.outbounderrorMessage = '';
        this.returnerrorMessage = '';
        this.showOutboundMetrics = this.clientPortalService.isAcronymAllowed(
          client,
          'OUT'
        );
        this.showReturnMetrics = this.clientPortalService.isAcronymAllowed(
          client,
          'RET'
        );
        this.showInboundMetrics = this.clientPortalService.isAcronymAllowed(
          client,
          'INB'
        );
        this.getKpiMetrics(client.trim());
      });
  }
 */
