import { AuthConfig } from 'angular-oauth2-oidc';


export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://ssociam-np.bpost.cloud',
  // Logout Url of the Identity Provider
  logoutUrl: 'https://ssociam-np.bpost.cloud/idp/startSLO.ping',
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/auth',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'radial_ciam_np',

  responseType: 'token id_token',
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. Also provide user sepecific
  scope: 'openid',
  showDebugInformation: false,
};
