import { LayoutModule } from '@angular/cdk/layout';
import { NgOptimizedImage } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { MaterialModule } from './app/material/material.module';
import { InterceptorService } from './app/services/interceptors/interceptor.service';
import { environment } from './environments/environment';
import './global';
if (environment.production) {
  enableProdMode();
  console.log = () => {};
}
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      MaterialModule,
      FlexLayoutModule,
      LayoutModule,
      MatToolbarModule,
      MatButtonModule,
      MatSidenavModule,
      MatIconModule,
      MatListModule,
      FormsModule,
      ReactiveFormsModule,
      NgxWebstorageModule.forRoot(),
      OAuthModule.forRoot({
        resourceServer: {
          allowedUrls: [environment.baseUrl],
          sendAccessToken: true,
        },
      }),
      NgxGoogleAnalyticsModule.forRoot(
        environment.googleAnalyticsMeasurementId
      ),
      NgxGoogleAnalyticsRouterModule,
      NgOptimizedImage
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.error(err));
