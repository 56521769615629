<header>
  <mat-toolbar
    color="primary"
    style="background-color: var(--radialGrey)"
    class="cp-header"
  >
    <mat-toolbar-row style="background-color: #ef2637; height: inherit">
      <a (click)="openFeedbackForm()" class="submit-feedback">
        <mat-icon
          style="vertical-align: middle"
          class="material-symbols-outlined"
        >
          forum </mat-icon
        >Submit Feedback</a
      >
      &nbsp;
    </mat-toolbar-row>
    <mat-toolbar-row class="cp-menubar">
      <button
        id="cp-menuBtn"
        #menuBtn
        mat-icon-button
        style="color: rgba(0, 0, 0, 0.87)"
        matTooltip="Application Menu"
        (click)="toggleMenu(sidenav, menuBtn)"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <img
        (click)="goToFirstModule(); sidenav.close()"
        matTooltip="Home"
        style="cursor: pointer"
        id="image"
        width="143"
        height="44"
        ngSrc="assets/images/radial_logo_2023.png"
        priority
        alt="Radial"
      />
      <span style="flex: 1 1 auto"></span>
      @if(shouldShowClientDD ||shouldShowWareHouseDD ){
      <div class="header-right-section">
        @if(shouldShowClientDD){
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          class="cp-mat-options-fit-content top-bar-dropdwons client-dropdown no-top-margin"
        >
          <mat-select
            panelClass="drop-down"
            [(ngModel)]="selectedCompany"
            (selectionChange)="
              sidenav.close() && getModulesByClient(selectedCompany, true)
            "
            matTooltip="Select Client"
            class="no-user-select"
          >
            @for (client of clientsFromUserDetails; track client) {
            <mat-option [value]="client.client_acronym">
              {{ client.client_name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        } @if(shouldShowWareHouseDD){
        <app-warehouse-dropdown
          (selectedWareHouse)="onWareHouseChange()"
        ></app-warehouse-dropdown>
        }
      </div>
      }
      <button
        mat-button
        mat-button
        [matMenuTriggerFor]="userInfo"
        class="cp-logged-in-userinfo"
        [matTooltip]="userName"
      >
        {{ userName[0] }}
      </button>
      <mat-menu
        #userInfo="matMenu"
        xPosition="before"
        class="cp-user-info-menu"
      >
        <div class="cp-user-menu" (click)="userInfoInsideClicked($event)">
          <div id="cp-user-name">
            <mat-icon class="material-icons-outlined icon">person_pin</mat-icon
            >{{ userName }}
          </div>
          <div id="cp-user-role">
            <mat-icon class="material-icons-outlined icon"
              >manage_accounts</mat-icon
            >
            <span style="color: grey; margin-right: 5px"> Role: </span>
            {{ roleName || "----" }}
          </div>
          <div id="cp-user-email">
            <mat-icon class="material-icons-outlined icon"
              >mail_outline</mat-icon
            >
            {{ clientEmail }}
          </div>
          <mat-divider></mat-divider>
          <div (click)="logout()" id="cp-user-logOut">
            <mat-icon class="material-icons-outlined"> exit_to_app </mat-icon>
            <div>Log Out</div>
          </div>
        </div>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>
</header>
<mat-sidenav-container class="cp-side-nav-container">
  <mat-sidenav
    #sidenav
    mode="over"
    class="cp-side-nav"
    (closedStart)="shouldBodyScroll()"
    [class.cp-no-dropdowns]="!(shouldShowClientDD && shouldShowWareHouseDD)"
    autoFocus="false"
  >
    @if(!!ModulesList && ModulesList?.length > 0){
    <mat-nav-list dense>
      @for (module of ModulesList; track module.id;let i = $index;) {
      <ng-container>
        @if(!module.children || module.children?.length === 0){
        <a
          class="mat-elevation-z0 cp-menu-item cp-menu-level1"
          [class.active]="router.url.startsWith(module.routing_url)"
          [routerLink]="module.routing_url"
          (click)="sidenav.close()"
        >
          {{ module.module_name }} </a
        >} @else{
        <mat-accordion>
          <mat-expansion-panel
            [expanded]="module.isExpanded"
            class="mat-elevation-z0 cp-menu-expansion"
            dense
            (opened)="openThisMenuAndCloseOthers(i, ModulesList, module)"
          >
            <mat-expansion-panel-header class="cp-menu-item cp-menu-level1">
              {{ module.module_group }}
            </mat-expansion-panel-header>
            @for (subMenu of module.children; track $index) {
            @if(!subMenu.children || subMenu.children?.length === 0){
            <a
              [class.active]="router.url.startsWith(subMenu.routing_url)"
              class="cp-menu-item cp-menu-level2"
              [routerLink]="subMenu.routing_url"
              (click)="sidenav.close()"
            >
              {{ subMenu.module_name }}
            </a>
            } @else{
            <mat-expansion-panel
              class="mat-elevation-z0 cp-menu-expansion"
              [expanded]="subMenu.isExpanded"
              (opened)="openThisMenuAndCloseOthers(i, module.children, subMenu)"
              dense
              ><mat-expansion-panel-header class="cp-menu-item cp-menu-level2">
                {{ subMenu.module_name }}
              </mat-expansion-panel-header>
              @for (childMenu of subMenu.children; track $index) {
              <a
                class="cp-menu-item cp-menu-level3"
                [class.active]="router.url.startsWith(childMenu.routing_url)"
                [routerLink]="childMenu.routing_url"
                (click)="sidenav.close()"
              >
                {{ childMenu.module_name }}
              </a>
              }
            </mat-expansion-panel>
            } }
          </mat-expansion-panel></mat-accordion
        >
        }
      </ng-container>
      }
    </mat-nav-list>
    } @else{
    <div class="cp-no-menu">Menu's are not available</div>
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <section class="cp-main-content">
      <main class="cp-main">
        <router-outlet></router-outlet>
      </main>
    </section>
    @if(permittedRoutes && (router.url===permittedRoutes[0])){
    <app-footer></app-footer>
    }
  </mat-sidenav-content>
</mat-sidenav-container>
