<footer class="cp-footer">
  <div id="cp-app-version">Current Version: {{ appversion }}</div>
  @if(branchName && branchName.length>0){
  <div>
    <a [href]="branchURL" target="_blank" id="cp-branch-name">{{
      branchName
    }}</a>
  </div>
  }
</footer>
