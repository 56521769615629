import { CommonModule } from '@angular/common';
import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import { FeedbackService } from './../../services/feedback/feedback.service';
import { MessageSnackbarService } from './../../services/message-snackbar/message-snackbar.service';

@Component({
  selector: 'app-submit-feedback',
  templateUrl: './submit-feedback.component.html',
  styleUrls: ['./submit-feedback.component.css'],
  standalone: true,
  imports: [CommonModule,
    MatButtonModule, MatCardModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatSelectModule, MatProgressBarModule]
})
export class SubmitFeedbackComponent implements OnInit, OnDestroy {


  destroy$: Subject<boolean> = new Subject<boolean>();
  subjects = [{ name: 'Suggestion or Idea', acronym: 'IDEA' },
  { name: 'Something is not quite right', acronym: 'BUG' },
  { name: 'This is cool, I really like', acronym: 'LIKED' }];
  userName: any;
  feedbackForm = this.fb.group({
    subject: ['', Validators.required],
    usrEntEmail: ['', Validators.required],
    comments: ['', Validators.required]

  });
  feedbackService = inject(FeedbackService);
  showLoader: any;
  constructor(public fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SubmitFeedbackComponent>, public snackbarService: MessageSnackbarService) { }
  ngOnInit() {
    this.feedbackForm.get('usrEntEmail').setValue(this.data.email);
  }

  submitFeedback() {

    const payLoad = {

      userId: sessionStorage.getItem('loginuseruuid'),
      pingEmail: this.data?.email,
      usrEntEmail: this.feedbackForm.get('usrEntEmail').value,
      subject: this.feedbackForm.get('subject').value,
      comment: this.feedbackForm.get('comments').value
    };
    this.feedbackForm.markAllAsTouched();
    if (this.feedbackForm.valid) {


      this.showLoader = true;
      this.feedbackService.submitFeedback(payLoad).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.showLoader = false;
        this.snackbarService.successSnackbar('Thank you for your feedback. We have successfully received your message',
          'success-snack-bar');
        this.dialogRef.close();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
