import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InboundKpiMetrics } from '../../models/inbound-kpi-metrics.model';
import { KpiMetrics } from '../../models/kpi-metrics.model';
import { OutboundKpiMetrics } from '../../models/outbound-kpi-metrics.model';
import { ReturnKpiMetrics } from '../../models/return-kpi-metrics.model';

@Injectable({
  providedIn: 'root',
})
export class KpiMetricsService {
  apiUrl = environment.baseUrl;

  constructor(private readonly http: HttpClient) { }

  getKpiMetrics() {
    return this.http.get<KpiMetrics>(this.apiUrl);
  }

  getInboundKpiMetrics(companyname: string) {
    return this.http.get<InboundKpiMetrics>(
      this.apiUrl + '/inbound?name=' + companyname
    );
  }

  getOutboundKpiMetrics(companyname: string) {
    return this.http.get<OutboundKpiMetrics>(
      this.apiUrl + '/outbound?name=' + companyname
    );
  }

  getReturnKpiMetrics(companyname: string) {
    return this.http.get<ReturnKpiMetrics>(
      this.apiUrl + '/return?name=' + companyname
    );
  }

  getOrderByID(id: string): Observable<KpiMetrics> {
    return this.http.get<KpiMetrics>(this.apiUrl + '/orderdetails/' + id);
  }

  createOrder(Order: any): Observable<KpiMetrics> {
    return this.http
      .post<KpiMetrics>(this.apiUrl + '/Order', JSON.stringify(Order))
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  updateOrder(id: string, Order: any): Observable<KpiMetrics> {
    return this.http
      .put<KpiMetrics>(this.apiUrl + '/Order/' + id, JSON.stringify(Order))
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  // eslint-disable-next-line
  deleteOrder(id: string) {
    return this.http.delete<KpiMetrics>(this.apiUrl + '/Order/' + id).pipe(
      retry(1)
      // catchError(this.handleError)
    );
  }
}
