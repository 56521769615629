import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class BaseService {
  apiUrl = environment.pingUrl;
  constructor(public http: HttpClient) {}
  getUserInfo() {
    return this.http.post<any>(this.apiUrl, null);
  }
}
