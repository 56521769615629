import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ClientPortalService } from '../client-user-config-service/client-portal.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    public auth: OAuthService,
    public router: Router,
    public snackBar: MatSnackBar,
    public clientPortalService: ClientPortalService
  ) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.auth.hasValidAccessToken()) {
      /**
       * route.routeConfig.path will return parent path of any child route. Since, route gaurd applied to only parent routes
       *so, if we validate with parent route it will allow all its child routes.
       * ex: Outbound(parent) => OrderDetails(childroute)
       */
      if (this.clientPortalService.permittedMenuItems) {
        if (
          this.clientPortalService.permittedMenuItems.includes(
            '/' + route.routeConfig.path
          )
        ) {
          return true;
        } else {
          this.router.navigate([
            this.clientPortalService.permittedMenuItems[0],
          ]);
        }
      } else {
        this.router.navigate(['/']);
      }
    } else {
      this.snackBar.open(
        'Your session has expired, please log in again',
        'close',
        {
          duration: 5000,
          panelClass: ['error-snack-bar'],
        }
      );
      this.router.navigate(['']);
      return false;
    }
  }
}
